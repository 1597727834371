import React, { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import authService from "../../services/auth.service";
import { AuthContext } from "../../context/auth.context";
import { LanguageContext } from '../../context/language.context';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function VerificationPage() {
    const [code, setCode] = useState("");
    const [errorMessage, setErrorMessage] = useState(undefined);
    const location = useLocation();
    const navigate = useNavigate();
    const { email } = location.state || {};
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const { storeToken, authenticateUser } = useContext(AuthContext);
    const { strings } = useContext(LanguageContext);

    const handleCodeChange = (e) => {
        const value = e.target.value;
        if (value.length <= 6 && /^\d*$/.test(value)) {
            setCode(value);
        }
    };

    const handlePassword = (e) => setPassword(e.target.value);

    const handleVerifySubmit = (e) => {
        e.preventDefault();
        const requestBody = { email, code, password };

        if (!email) {
            setErrorMessage('Email not found, please try logging in or signing up');
            return;
        }

        if (!code) {
            setErrorMessage('Please enter the code');
            return;
        }

        if (code.length !== 6) {
            setErrorMessage('Code must be 6 digits long');
            return;
        }

        if (!password) {
            setErrorMessage('Please enter your password');
            return;
        }

        authService
            .verifyCode(requestBody)
            .then((response) => {
                storeToken(response.data.accessToken, response.data.refreshToken);
                authenticateUser();
                navigate("/mycourses");
            })
            .catch((error) => {
                const errorDescription = error.response?.data?.message || "An error occurred";
                setErrorMessage(errorDescription);
            });
    };

    return (
        <div className="items-center containerNavbar">
            <div className="flex flex-col justify-center p-6 lg:p-8 bg-[#FFFFFF1A] lg:w-1/2 rounded-[16px] sm:mt-[5%] border border-[#A892FF33] max-w-[450px] w-full">
                <h2 className="mb-4 text-[20px] sm:text-[24px] leading-7 font-bold text-center text-white">Verifique a sua conta</h2>
                <p className="mb-4 text-sm text-center sm:text-base text-primary">Insira o código recebido por email</p>
                <form onSubmit={handleVerifySubmit} className="flex flex-col gap-4">
                    <input
                        className="w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit"
                        type="text"
                        name="code"
                        maxLength={6}
                        placeholder="Código"
                        value={code}
                        onChange={handleCodeChange}
                    />
                    <div className="relative flex">
                        <input
                            className="w-full px-4 h-12 pr-10 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder={strings.login.password}
                            value={password}
                            onChange={handlePassword}
                        />
                        <button
                            className="absolute right-0 py-3 pr-3 transform -translate-y-1/2 top-1/2"
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <FaEyeSlash className="text-secondary" /> : <FaEye className="text-secondary" />}
                        </button>
                    </div>
                    {errorMessage && <p className="text-sm text-red-500">{errorMessage}</p>}
                    <button className="hover:bg-primary-hover rounded bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]" type="submit">Verificar</button>
                </form>
            </div>
        </div>
    );
}

export default VerificationPage;
