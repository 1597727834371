import React, { useState, useContext, useEffect } from "react";
import { LanguageContext } from '../../context/language.context';
import toast from 'react-hot-toast';
import axios from 'axios';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

function ResetPasswordPage() {
    const { strings } = useContext(LanguageContext);
    const [page, setPage] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
    }, [page])


    /*** PAGE 0 ***/
    const [email, setEmail] = useState("");
    const handleEmail = (e) => setEmail(e.target.value);
    /*** PAGE 0 END ***/

    /*** PAGE 1 ***/
    const [codes, setCodes] = useState(['', '', '', '', ""]);
    const inputRefs = Array.from({ length: 5 }, () => React.createRef()); // Create refs for each input
    /*** PAGE 1 END ***/

    /*** PAGE 2 ***/
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showNewPasswordRepeat, setShowNewPasswordRepeat] = useState(false);
    /*** PAGE 2 END ***/

    /*** PAGE 0 ***/
    //Handle input errors and call the function sendResetPasswordCodeDB
    const toastSendResetPasswordCode = async () => {
        if (!validateEmail(email)) {
            toast.error("Email não é válido");
            return;
        }

        toast.promise(
            sendResetPasswordCodeDB(),
            {
                loading: <b>{strings.toast.toastLoading}</b>,
                success: <b>{strings.resetPassword.sendCodeEmail}</b>,
                error: (err) => handleErrorSendResetPasswordCode(err),
            }
        );
    }

    const sendResetPasswordCodeDB = () => {
        return new Promise((resolve, reject) => {
            axios.patch(`https://dionamiteacademyapi.com:41278/user/createResetPasswordCode`, { email })
                .then(response => {
                    resolve(response.data);
                    setPage(1)
                })
                .catch(error => {
                    console.log(error)
                    reject(error);
                });
        });
    };

    //Handle difrent types of error messages on the function toastResetPassword
    const handleErrorSendResetPasswordCode = (err) => {
        if (err.response.data.message === "Email dont exist") {
            return strings.resetPassword.emailNotValid;
        } else if (err.response.data.message === "Google User") {
            return strings.resetPassword.googleAccount;
        } else {
            return strings.toast.toastError;
        }
    }

    function validateEmail(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }
    /*** PAGE 0 END ***/

    /*** PAGE 1 ***/
    //Handle inputs
    const handleChange = (e, index) => {
        const { value } = e.target;
        if (value.length > 1) {
            // If user enters more than one character, only take the first character
            const newValue = value.substring(0, 1);
            const newCodes = [...codes];
            newCodes[index] = newValue;
            setCodes(newCodes);
            if (newValue !== '' && index < 4) {
                inputRefs[index + 1].current.focus(); // Move focus to next input
            }
        } else {
            const newCodes = [...codes];
            newCodes[index] = value;
            setCodes(newCodes);
            if (value !== '' && index < 4) {
                inputRefs[index + 1].current.focus(); // Move focus to next input
            } else if (value === '' && index > 0) {
                inputRefs[index - 1].current.focus(); // Move focus to previous input when deleting
            }
        }
    };

    //Handle deletion inputs 
    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && index > 0 && codes[index] === '') {
            inputRefs[index - 1].current.focus(); // Move focus to previous input when pressing backspace
        }
    };

    // Check if every input is not empty
    const isAllFilled = () => {
        return codes.every(code => code !== '');
    };

    const toastCheckResetPasswordCode = async () => {
        if (isAllFilled)
            toast.promise(
                checkResetPasswordCodeDB(),
                {
                    loading: <b>{strings.toast.toastLoading}</b>,
                    success: <b>{strings.resetPassword.codeValid}</b>,
                    error: (err) => handleErrorCheckResetPasswordCode(err),
                }
            );
    }

    //Handle difrent types of error messages on the function toastCheckResetPasswordCode
    const handleErrorCheckResetPasswordCode = (err) => {
        if (err.response.data.message === "Invalid code") {
            return strings.resetPassword.codeInvalid;
        } else if (err.response.data.message === "Code expired") {
            return strings.resetPassword.codeExpired;
        } else {
            return strings.toast.toastError;
        }
    }

    const checkResetPasswordCodeDB = () => {
        return new Promise((resolve, reject) => {
            const codesString = codes.join('');
            axios.post(`https://dionamiteacademyapi.com:41278/user/checkResetPasswordCode`, { "email": email, "code": codesString })
                .then(response => {
                    resolve(response.data);
                    setPage(2)
                })
                .catch(error => {
                    console.log(error)
                    reject(error);
                });
        });
    };
    /*** PAGE 1 END ***/

    /*** PAGE 2 ***/
    const handleNewPassword = (e) => {
        setNewPassword(e.target.value);
    }

    const handleNewPasswordRepeat = (e) => {
        setNewPasswordRepeat(e.target.value);
    }

    //Handle difrent types of error messages on the function toastResetPassword
    const handleErrorChangePassword = (err) => {
        if (err.response.data.message === "Password not secure") {
            document.getElementById("passwordStrength").classList.remove("hidden");
            return strings.resetPassword.passwordStrengthModal;
        } else {
            return strings.toast.toastError;
        }
    }

    const toastResetPassword = async () => {
        if (!newPassword || !newPasswordRepeat) {
            toast.error('Preencha todos os campos');
            return
        }
        
        if (newPassword !== newPasswordRepeat) {
            toast.error(strings.resetPassword.passwordsDontMatch);
            return;
        }

        toast.promise(
            resetPasswordDB(),
            {
                loading: <b>{strings.toast.toastLoading}</b>,
                success: <b>{strings.resetPassword.success}</b>,
                error: (err) => handleErrorChangePassword(err),
            }
        );
    }

    const resetPasswordDB = () => {
        return new Promise((resolve, reject) => {
            axios.patch(`https://dionamiteacademyapi.com:41278/user/resetPassword`, { "email": email, "password": newPassword })
                .then(response => {
                    resolve(response.data);
                    navigate("/login")
                })
                .catch(error => {
                    console.log(error)
                    reject(error);
                });
        });
    };
    /*** PAGE 2 END ***/

    return (
        <div className="items-center justify-start containerNavbar">
            {page === 0 &&
                <div className="flex flex-col justify-center p-6 lg:p-8 bg-[#FFFFFF1A] lg:w-1/2 rounded-[16px] sm:mt-[5%] border border-[#A892FF33] max-w-[392px] lg:max-w-none w-full">
                    <h2 className="text-center text-white text-[20px] sm:text-[24px] leading-7 font-bold mb-6">Esqueceu-se da palavra-passe?</h2>
                    <p className="mb-4 text-sm text-white">{strings.resetPassword.page1Description}</p>
                    <input className="w-full mb-4 px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit" type="email" name="email" placeholder="Email" value={email} onChange={handleEmail} />
                    <button onClick={toastSendResetPasswordCode} className="hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]">{strings.resetPassword.sendEmail}</button>
                </div>
            }
            {page === 1 &&
                <div className="flex flex-col justify-center p-6 lg:p-8 bg-[#FFFFFF1A] lg:w-1/2 rounded-[16px] sm:mt-[5%] border border-[#A892FF33] max-w-[392px] lg:max-w-none w-full">
                    <h2 className="text-center text-white text-[20px] sm:text-[24px] leading-7 font-bold mb-6">Código de verificação</h2>
                    <p className="mb-4 text-sm text-white">{strings.resetPassword.page2Description}</p>
                    <div className="flex items-center justify-center mb-5">
                        {codes.map((code, index) => (
                            <input
                                key={index}
                                ref={inputRefs[index]}
                                type="number"
                                maxLength="1"
                                value={code}
                                onChange={(e) => handleChange(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                className="w-12 h-12 mx-1 text-2xl text-center bg-[#FFFFFF1A] border text-white border-[#6753B41A] outline-none focus:outline-primary rounded"
                            />
                        ))}
                    </div>
                    <button onClick={toastCheckResetPasswordCode} className="hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]">{strings.resetPassword.continue}</button>
                </div>
            }
            {page === 2 &&
                <div className="flex flex-col justify-center p-6 lg:p-8 bg-[#FFFFFF1A] lg:w-1/2 rounded-[16px] sm:mt-[5%] border border-[#A892FF33] max-w-[392px] lg:max-w-none w-full">
                    <h2 className="text-center text-white text-[20px] sm:text-[24px] leading-7 font-bold mb-6">Repor palavra-passe</h2>
                    <div className="flex flex-col w-full mb-4 gap-y-4">
                        <div className="relative flex">
                            <input
                                className="w-full px-4 h-12 pr-10 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit"
                                type={showNewPassword ? "text" : "password"}
                                name="password"
                                placeholder="Nova palavra-passe"
                                value={newPassword}
                                onChange={handleNewPassword}
                            />
                            <button
                                className="absolute right-0 h-12 px-3 py-3 transform -translate-y-1/2 top-1/2"
                                type="button"
                                onClick={() => setShowNewPassword(!showNewPassword)}
                            >
                                {showNewPassword ? <FaEyeSlash className="text-secondary" /> : <FaEye className="text-secondary" />}
                            </button>
                        </div>
                        <div className="relative flex">
                            <input
                                id='newPasswordRepeat'
                                className="w-full px-4 h-12 pr-10 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit"
                                type={showNewPasswordRepeat ? "text" : "password"}
                                name="password"
                                placeholder={strings.resetPassword.confirmNewPassword}
                                value={newPasswordRepeat}
                                onChange={handleNewPasswordRepeat}
                            />
                            <button
                                className="absolute right-0 h-12 px-3 py-3 transform -translate-y-1/2 top-1/2"
                                type="button"
                                onClick={() => setShowNewPasswordRepeat(!showNewPasswordRepeat)}
                            >
                                {showNewPasswordRepeat ? <FaEyeSlash className="text-secondary" /> : <FaEye className="text-secondary" />}
                            </button>
                        </div>
                        <div id='passwordStrength' className='hidden mt-1 text-red-500'>{strings.resetPassword.passwordStrength}</div>
                    </div>
                    <button onClick={toastResetPassword} className="hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]">{strings.resetPassword.resetPassword}</button>
                </div>
            }
        </div>
    );
}

export default ResetPasswordPage;