import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast'
import { GoArrowLeft } from "react-icons/go";
import { useNavigate } from 'react-router-dom';

const CategoriesPage = () => {
    const [categories, setCategories] = useState([]);
    const [showCreateInput, setShowCreateInput] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const navigate = useNavigate()

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get('https://dionamiteacademyapi.com:41278/category/getAll');
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);

    const handleCreateCategory = async () => {
        if (!newCategoryName) {
            toast.error('Please enter a category name');
            return;
        }

        try {
            const response = await axios.post('https://dionamiteacademyapi.com:41278/category/createCategory', { name: newCategoryName }, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
            setCategories([...categories, response.data]);
            setNewCategoryName('');
            setShowCreateInput(false);
        } catch (error) {
            console.error('Error creating category:', error);
        }
    };

    const handleDeleteCategory = async (categoryId) => {
        if (window.confirm("Are you sure you want to remove this category?")) {
            try {
                await axios.delete(`https://dionamiteacademyapi.com:41278/category/deleteCategory/${categoryId}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } });
                // Update categories state to remove the deleted category
                setCategories(categories.filter(category => category._id !== categoryId));
            } catch (error) {
                console.error('Error deleting category:', error);
            }
        }
    };

    return (
        <div className="lg:py-8 containerNavbar">
            <div className="fixed hidden p-2 bg-white rounded-full lg:block left-[3%] 2xl:left-[6%] cursor-pointer top-32 z-[100]" onClick={() => navigate('/dashboard')}>
                <GoArrowLeft className="text-black size-6" />
            </div>
            <h1 className="mb-6 text-2xl font-bold text-white sm:text-4xl">Categorias</h1>
            <div>
                <button
                    onClick={() => setShowCreateInput(!showCreateInput)}
                    className={`transition duration-500 ease-in-out rounded max-sm:w-full mb-6 text-center ${showCreateInput ? 'bg-red-500 hover:bg-red-600 hover:text-white' : 'bg-primary hover:bg-primary-hover'} text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]`}>
                    {showCreateInput ? 'Cancelar' : 'Criar Categoria'}
                </button>
            </div>
            {showCreateInput && (
                <div className="flex flex-col mt-2 space-y-4 sm:space-y-0 sm:flex-row">
                    <input
                        type="text"
                        value={newCategoryName}
                        onChange={(e) => setNewCategoryName(e.target.value)}
                        className="w-full sm:w-1/2 px-4 mr-2 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-gray-400 focus:outline-primary"
                        placeholder="Enter category name"
                    />
                    <button onClick={handleCreateCategory} className="hover:bg-primary-hover transition duration-500 ease-in-out rounded text-center bg-primary text-[11px] sm:text-[12px] uppercase font-bold h-12 px-6 text-white hover:text-textHover tracking-[2px]">
                        Adicionar
                    </button>
                </div>
            )}

            <div className="my-10 overflow-x-auto rounded-[16px] border border-[#A892FF33]">
                <table className="table w-full bg-[#FFFFFF1A] table-auto">
                    <thead className="text-textHover bg-primary-hover">
                        <tr>
                            <th className="px-4 py-2 border-r border-primary">Nome</th>
                            <th className="px-4 py-2">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {categories.map((category) => (
                            <tr key={category._id} className="border-b border-[#A892FF33] text-sm sm:text-base">
                                <td className="w-full px-4 py-2 text-secondary border-r border-[#A892FF33]">{category.name}</td>
                                <td className="px-2 py-2">
                                    <button
                                        onClick={() => handleDeleteCategory(category._id)}
                                        className="px-4 py-2 text-sm font-bold text-white bg-red-500 rounded hover:bg-red-600"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CategoriesPage;
