import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LanguageContext } from '../../context/language.context';

function CookieConsentBanner() {
  const [showBanner, setShowBanner] = useState(false);
  const location = useLocation();
  const { strings } = useContext(LanguageContext);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent !== 'true') {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  const isCookiesPage = location.pathname === '/cookies';

  if (isCookiesPage) {
    // Banner layout for '/cookies' page
    return (
      <div className="fixed bottom-0 z-50 w-full">
        <div className="flex flex-row items-center justify-center p-4 space-x-6 text-sm text-center text-white bg-gray-800 shadow-lg sm:text-base">
          <p>{strings.cookieBanner.message}</p>
          <button className="px-4 py-2 font-bold text-white rounded bg-primary hover:bg-primary-hover" onClick={handleAccept}>
            {strings.cookieBanner.accept}
          </button>
        </div>
      </div>
    );
  }

  // Default banner layout for other pages
  return (
    <div className="fixed bottom-0 right-0 z-50 flex items-end justify-end mx-8 mb-8">
      <div className="w-full max-w-lg p-8 text-left text-white bg-gray-800 rounded-lg shadow-lg">
        <h1 className='mb-2 text-lg sm:text-xl'>Cookies</h1>
        <p className='text-sm sm:text-base'>{strings.cookieBanner.message} <Link to="/cookies" className="text-primary hover:text-primary-hover">{strings.cookieBanner.learnMore}</Link></p>
        <button className="w-full py-2 mt-4 text-sm font-bold text-white rounded bg-primary hover:bg-primary-hover sm:text-base" onClick={handleAccept}>
          {strings.cookieBanner.accept}
        </button>
      </div>
    </div>
  );
}

export default CookieConsentBanner;