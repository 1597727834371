import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaBookOpen, FaUser, FaStar, FaInfinity } from 'react-icons/fa';
import axios from 'axios';
import { FaHeart, FaRegHourglassHalf, FaVolumeHigh } from "react-icons/fa6";
import { GrTask } from "react-icons/gr";
import { LanguageContext } from '../../context/language.context';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../../context/auth.context";
import { SlSpeedometer } from "react-icons/sl";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import { TfiWrite } from "react-icons/tfi";

const CoursePage = () => {
    const { slug } = useParams();
    const { user } = useContext(AuthContext);
    const [course, setCourse] = useState(null);
    const [modules, setModules] = useState([]);
    const { strings } = useContext(LanguageContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                const response = await axios.get(`https://dionamiteacademyapi.com:41278/course/getCourseBySlug/${slug}`);
                setCourse(response.data);
                const fetchedModules = response.data.modules.length > 0 ? response.data.modules : [];
                setModules(fetchedModules);
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };

        fetchCourse();
    }, []);

    const audioOptions = {
        portuguese: 'Português',
        english: 'Inglês'
    };

    const typeOptions = {
        online: 'Online',
        accompanied: 'Acompanhado'
    };

    const difficultyOptions = {
        beginner: 'Fácil',
        intermediate: 'Médio',
        advanced: 'Difícil',
        'ultra-advanced': 'Ultra-Avançado'
    };

    const formatDescription = (description) => {
        if (!description) return '';
        return description.replace(/\n/g, '<br />');
    };

    return (
        <div className="mb-20 lg:py-8 containerNavbar">
            {course && (
                <div className='flex flex-col lg:space-x-16 lg:flex-row'>
                    <div className='lg:w-4/5'>
                        <h1 className="mb-2 text-3xl font-bold text-white sm:text-4xl">{course?.title}</h1>
                        <p className="inline-flex items-center mb-8 text-xs font-light text-white sm:text-sm">
                            {strings.courses.course.aCourseOf}<span className='ml-1 font-semibold text-dionamiteOrange'>{course?.creator?.firstName} {course?.creator?.lastName}</span>
                        </p>
                        {course?.trailerURL ?
                            <VideoPlayer url={course?.trailerURL} />
                            :
                            <img className='w-full' src={course?.image} />
                        }

                        <div className="flex my-10">
                            <p className="text-sm text-white sm:text-base" dangerouslySetInnerHTML={{ __html: formatDescription(course?.description) }} />
                        </div>

                        {modules?.length > 0 && (
                            <div className="p-4 bg-[#FFFFFF1A] rounded-[16px]">
                                {/*   <h2 className="mb-8 text-lg font-bold text-white sm:text-2xl">{strings.courses.course.courseCurriculum}</h2> */}

                                {modules.map((module, index) => (
                                    <div key={module?._id} className='p-2'>
                                        <div className='border-b border-gray-200'>
                                            <h2 className="pb-4 text-lg font-bold sm:text-xl text-secondary">M{index + 1}: {module?.name}</h2>
                                        </div>
                                        {module.lessons.map(lesson => (
                                            <div key={lesson?._id} className='flex items-center my-4 ml-2 space-x-4 text-white sm:ml-4 w-fit'>
                                                <TfiWrite className='flex-shrink-0' />
                                                <h2 className="text-sm font-semibold text-white sm:text-base">{lesson?.name}</h2>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        )}

                        {course?.reviews?.length > 0 && <div className="mt-8">
                            <h2 className="mb-3 text-2xl font-semibold text-white">{strings.courses.course.reviews}</h2>
                        </div>}

                    </div>
                    <div className='mt-10 lg:w-1/5'>

                        {course?.purchased?.some(purchase => purchase.userId === user?._id) ? (
                            <div className='py-5 text-white border-b border-gray-200'>
                                <button className='w-full hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]' onClick={() => navigate(`/mycourses/${slug}`)}>Ver Conteúdo</button>
                            </div>
                        ) : (
                            <div className='py-5 text-white border-b border-gray-200'>
                                <button className='w-full hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[12px] sm:text-[13px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]' onClick={() => navigate(`/checkout/${slug}`)}>Pagar €{course?.price}</button>
                            </div>
                        )}

                        <div className='py-4 space-y-4 text-sm text-white border-b border-gray-200'>
                            <div className='flex items-start mb-2'>
                                <FaRegHourglassHalf className='flex-shrink-0 mr-2 mt-[3px] text-dionamiteOrange' />
                                <div className='flex flex-col space-y-[2px]'>
                                    <b>{strings.courses.course.duration}:</b>
                                    <span>{course?.duration} {strings.courses.course.hours}</span>
                                </div>
                            </div>
                            <div className='flex items-start mb-2'>
                                <FaBookOpen className='flex-shrink-0 mr-2 mt-[3px] text-green-500' />
                                <div className='flex flex-col space-y-[2px]'>
                                    <b>{strings.courses.course.type}:</b>
                                    <span>{typeOptions[course?.type]}</span>
                                </div>
                            </div>
                            <div className='flex items-start mb-2'>
                                <FaVolumeHigh className='flex-shrink-0 mr-2 mt-[3px] text-blue-500' />
                                <div className='flex flex-col space-y-[2px]'>
                                    <b>{strings.courses.course.audio}:</b>
                                    <span>{audioOptions[course?.audio]}</span>
                                </div>
                            </div>
                            <div className='flex items-start mb-2'>
                                <SlSpeedometer className='flex-shrink-0 mr-2 mt-[3px] text-red-500' />
                                <div className='flex flex-col space-y-[2px]'>
                                    <b>Dificuldade:</b>
                                    <span>{difficultyOptions[course?.difficulty]}</span>
                                </div>
                            </div>
                            <div className='flex items-start mb-2'>
                                <GrTask className='flex-shrink-0 mr-2 mt-[3px] text-primary' />
                                <div className='flex flex-col space-y-[2px]'>
                                    <b>{strings.courses.course.requirements}:</b>
                                    <span>{course?.requirements}</span>
                                </div>
                            </div>
                            <div className='flex items-start mb-2'>
                                <FaUser className='flex-shrink-0 mr-2 mt-[3px] text-pink-500' />
                                <div className='flex flex-col space-y-[2px]'>
                                    <b>{strings.courses.course.forWhom}:</b>
                                    <span>{course?.forWhom}</span>
                                </div>
                            </div>
                            {course?.likedBy?.length > 0 && (
                                <div className='flex items-start mb-2'>
                                    <FaHeart className='flex-shrink-0 mr-2 mt-[3px] text-red-500' />
                                    <div className='flex flex-col space-y-[2px]'>
                                        <b>{strings.courses.course.likes}:</b>
                                        <span>{course?.likedBy?.length}</span>
                                    </div>
                                </div>
                            )}
                            {course?.rating > 0 && (
                                <div className='flex items-start mb-2'>
                                    <FaStar className='flex-shrink-0 mr-2 mt-[3px] text-yellow-500' />
                                    <div className='flex flex-col space-y-[2px]'>
                                        <b>{strings.courses.course.rating}":</b>
                                        <span>{course?.rating}/5</span>
                                    </div>
                                </div>
                            )}
                            {/*                             <div className='flex items-center'><FaInfinity className='mr-2' />{strings.courses.course.access}: {course?.access}</div> */}
                        </div>
                        <div>
                            <h2 className="py-5 text-2xl font-semibold text-white">{strings.courses.course.categories}</h2>
                            <div className='flex flex-row flex-wrap gap-2'>
                                {course?.categories.map(categoria => (
                                    <div
                                        onClick={() => navigate(`/allcourses?categoryFilter=${categoria._id}`)}
                                        key={categoria._id}
                                        className="flex items-center justify-center flex-grow h-8 px-3 overflow-hidden transition-shadow duration-300 bg-gray-100 rounded shadow-md cursor-pointer hover:shadow-lg"
                                    >
                                        <h2 className="text-xs font-semibold">{categoria.name}</h2>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>
            )}
        </div>
    );
}

export default CoursePage;
