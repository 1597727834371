import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HiArrowLongRight } from "react-icons/hi2";
import { LanguageContext } from '../../context/language.context';
import { GoArrowLeft } from "react-icons/go";

const CoursesPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [courses, setCourses] = useState([]);
    const [categories, setCategories] = useState([]);
    const [filter, setFilter] = useState(queryParams.get('filter') || '');
    const [statusFilter, setStatusFilter] = useState(queryParams.get('statusFilter') || '');
    const [categoryFilter, setCategoryFilter] = useState(queryParams.get('categoryFilter') || '');
    const [audioFilter, setAudioFilter] = useState(queryParams.get('audioFilter') || '');
    const { strings } = useContext(LanguageContext);

    const navigate = useNavigate();

    const audioOptions = [
        { label: strings.courses.audioOptions.portuguese, value: 'portuguese' },
        { label: strings.courses.audioOptions.english, value: 'english' }
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [coursesResponse, categoriesResponse] = await Promise.all([
                    axios.get('https://dionamiteacademyapi.com:41278/course/getAll'),
                    axios.get('https://dionamiteacademyapi.com:41278/category/getAll')
                ]);

                setCourses(coursesResponse.data);

                const formattedCategories = categoriesResponse.data.map(cat => ({
                    label: cat.name,
                    value: cat._id
                }));
                setCategories(formattedCategories);

                handleStatusFilter("active");
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        setFilter(queryParams.get('filter') || '');
        setStatusFilter(queryParams.get('statusFilter') || '');
        setCategoryFilter(queryParams.get('categoryFilter') || '');
        setAudioFilter(queryParams.get('audioFilter') || '');
    }, [location.search]);

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
        updateUrlParams({ filter: e.target.value });
    };

    const handleStatusFilter = (status) => {
        setStatusFilter(status);
        updateUrlParams({ statusFilter: status });
    };

    const handleAudioChange = (selectedOption) => {
        setAudioFilter(selectedOption ? selectedOption.value : '');
        updateUrlParams({ audioFilter: selectedOption ? selectedOption.value : '' });
    };

    const handleCategoryChange = (selectedOption) => {
        setCategoryFilter(selectedOption ? selectedOption.value : '');
        updateUrlParams({ categoryFilter: selectedOption ? selectedOption.value : '' });
    };

    const updateUrlParams = (params) => {
        const urlSearchParams = new URLSearchParams(location.search);
        Object.entries(params).forEach(([key, value]) => {
            if (value !== '') {
                urlSearchParams.set(key, value);
            } else {
                urlSearchParams.delete(key);
            }
        });
        navigate(`?${urlSearchParams.toString()}`);
    };

    const filteredCourses = courses.filter(course => {
        return (statusFilter ? course.status === statusFilter : true) &&
            (categoryFilter ? course.categories.some(category => category._id === categoryFilter) : true) &&
            (audioFilter ? course.audio === audioFilter : true) &&
            course.title.toLowerCase().includes(filter.toLowerCase());
    });

    const handleCourseClick = (courseId) => {
        navigate(`/courses-admin/${courseId}`);
    };

    const customStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: '#CFC4F814',
            borderColor: '#6753B41A',
            borderWidth: '0px',
            boxShadow: 'none',
            '&:hover': {
                borderColor: '#cbd5e1'
            }
        }),
        placeholder: (styles) => ({
            ...styles,
            color: 'secondary' // Custom placeholder color
        }),
        singleValue: (styles) => ({
            ...styles,
            color: 'secondary' // Custom text color (example: primary)
        }),
        input: (styles) => ({
            ...styles,
            color: 'secondary' // Custom search input text color
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            color: 'secondary', // Custom color for the dropdown arrow
            '&:hover': {
                color: '#6753B4' // Custom hover color for the dropdown arrow
            }
        }),
        clearIndicator: (styles) => ({
            ...styles,
            color: 'secondary', // Custom color for the clear "X" button
            '&:hover': {
                color: '#6753B4' // Custom hover color for the clear "X" button
            }
        }),
        menu: (styles) => ({
            ...styles,
            borderRadius: '0.25rem',
            boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)',
        }),
        option: (styles, { isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? '#ebf8ff' : isSelected ? '#bee3f8' : undefined,
                color: '#1a202c', // Gray-800
                cursor: 'pointer',
                '&:active': {
                    backgroundColor: '#bee3f8',
                },
            };
        },
    };

    return (
        <div className="mb-20 lg:py-8 containerNavbar">
            <div className="fixed hidden p-2 bg-white rounded-full lg:block left-[3%] 2xl:left-[6%] cursor-pointer top-32 z-[100]" onClick={() => navigate('/dashboard')}>
                <GoArrowLeft className="text-black size-6" />
            </div>
            <h1 className="mb-6 text-2xl font-bold text-white sm:text-4xl">Cursos</h1>

            <div className='flex flex-col'>
                <Link to={'/create-course'} className="hover:bg-primary-hover transition duration-500 ease-in-out rounded w-full sm:w-1/3 xl:w-1/4 mb-6 text-center bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]">
                    Adicionar curso
                </Link>

                <input
                    type="text"
                    placeholder={strings.courses.searchCourses}
                    className="block lg:hidden w-full px-4 mb-6 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit"
                    value={filter}
                    onChange={handleFilterChange}
                />

                <div className='flex flex-col self-end w-full mb-8 space-y-4 sm:space-y-0 lg:w-[45%] sm:space-x-4 sm:flex-row'>
                    <Select
                        options={categories}
                        onChange={handleCategoryChange}
                        className="w-full text-xs text-secondary outline-none bg-[#CFC4F814] border border-[#6753B41A] rounded focus:outline-none placeholder-inherit font-normal tracking-[1px]"
                        styles={customStyles}
                        placeholder={strings.courses.category}
                        isClearable
                    />

                    <Select
                        options={audioOptions}
                        onChange={handleAudioChange}
                        className="w-full text-xs text-secondary outline-none bg-[#CFC4F814] border border-[#6753B41A] rounded focus:outline-none placeholder-inherit font-normal tracking-[1px]"
                        styles={customStyles}
                        placeholder={strings.courses.audio}
                        isClearable
                    />


                </div>
            </div>

            <div className="flex justify-center mb-8 space-x-2">
                <button
                    className={`px-6 py-3 text-xs rounded uppercase font-bold transition duration-500 ease-in-out ${statusFilter === 'active' ? 'bg-primary-hover text-textHover' : 'text-white bg-gray-800 hover:bg-gray-900'}`}
                    onClick={() => handleStatusFilter('active')}
                >
                    Ativo
                </button>
                <button
                    className={`px-6 py-3 rounded text-xs uppercase font-bold transition duration-500 ease-in-out ${statusFilter === 'inactive' ? 'bg-primary-hover text-textHover' : 'text-white bg-gray-800 hover:bg-gray-900'}`}
                    onClick={() => handleStatusFilter('inactive')}
                >
                    Inativo
                </button>
            </div>

            {filteredCourses.length > 0 ?
                <>
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-y-10 gap-x-8">

                        {filteredCourses.map(course => (
                            <div key={course._id} className="flex flex-col bg-[#FFFFFF1A] rounded-[16px] overflow-hidden border border-[#A892FF33] transition duration-500 ease-in-out hover:shadow-card group cursor-pointer" onClick={() => handleCourseClick(course._id)}>
                                <img src={course?.image} alt={`${course.title}`} className='h-[180px] object-cover' />
                                <div className='flex flex-col justify-between h-full'>
                                    <div className='px-4 pt-4'>
                                        <p className='text-secondary font-bold text-[20px] leading-7'>{course?.title}</p>
                                        <p className='text-primary font-medium text-[14px] leading-6 mb-4'>{course?.difficulty}</p>
                                        <p className='text-white text-[14px] leading-6 font-medium line-clamp-4'>{course?.description}</p>
                                    </div>
                                    <div>
                                        <button className='flex text-left items-center mt-10 py-7 font-bold space-x-3 px-4 text-white uppercase transition duration-500 ease-in-out text-[12px] leading-4 tracking-[2px] border-t border-[#A892FF1A] w-full justify-between group-hover:bg-primary-hover group-hover:text-textHover'>
                                            <span>Ver Curso</span>
                                            <HiArrowLongRight className='size-6 min-w-6' />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
                :
                <div className='text-center'>
                    <p className='mt-4 text-xs text-white sm:text-base'>{strings.courses.noResults}</p>
                </div>
            }
        </div >
    );
}

export default CoursesPage;
