import React, { useContext } from 'react';
import { LanguageContext } from '../../context/language.context';
import { MdOutlineMail, MdOutlineMessage } from "react-icons/md";
import { FiInstagram } from 'react-icons/fi';
import { FaFacebook, FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {

    const { strings } = useContext(LanguageContext);

    return (
        <footer className='relative flex justify-center w-full overflow-hidden bg-gradient-to-b from-primary to-[#3C2D76]'>
            <div className='xl:w-[1920px] flex flex-col'>
                <img className='w-full mt-[30px] sm:mt-[73px] max-w-[1920px]' src='/DionamiteFooter.png' alt='Logo Dionamite' />
                {/* <span className='-ml-1 mt-1 xs:-ml-3 md:-ml-5 w-full text-[19vw] 2xl:text-[19rem] inline-block font-TitilliumWeb-Black text-center font-bold italic uppercase text-[#fff] select-none leading-[2rem] xs:leading-[2.5rem] sm:leading-[3rem] md:leading-[5rem] lg:leading-[6.5rem] xl:leading-[9rem] 2xl:leading-[10rem]'>
                    Dionamite
                </span> */}
                <div className='w-full max-w-[1440px] px-[50px] md:px-[120px] flex flex-col self-center'>
                    <div className='grid grid-cols-1 mt-8 md:grid-cols-2 lg:grid-cols-4 md:mt-[116px] gap-y-10'>
                        <div className='flex flex-col justify-start'>
                            <img className='w-44' src='/DionamiteAcademyWhite.png' alt='Logo Dionamite' />
                            <p className='mt-1 font-medium text-white text-[14px] leading-6'>
                                {strings.footer.portugal}
                            </p>
                            <Link to={"/support"} className='mt-4 font-bold uppercase transition-opacity text-dionamiteOrange text-[12px] leading-4 tracking-[2px]'>
                                Precisa de ajuda?
                            </Link>
                            <Link to={"/refund-policy"} className='mt-4 font-bold uppercase transition-opacity text-dionamiteOrange text-[12px] leading-4 tracking-[2px]'>
                                Política de Reembolso
                            </Link>
                        </div>
                        <div className='flex flex-col'>
                            <h6 className='font-bold text-white sm:mt-0 text-[18px] leading-6'>Menu</h6>
                            <Link to={"/allcourses"} className='mt-6 font-bold uppercase text-dionamiteOrange text-[12px] leading-4 tracking-[2px]'>
                                {strings.footer.nav1}
                            </Link>
                            <Link to={"/mycourses"} className='mt-4 font-bold uppercase text-dionamiteOrange text-[12px] leading-4 tracking-[2px]'>
                                {strings.footer.nav2}
                            </Link>
                            <Link to={"/profile"} className='mt-4 font-bold uppercase text-dionamiteOrange text-[12px] leading-4 tracking-[2px]'>
                                {strings.footer.nav3}
                            </Link>
                        </div>
                        <div>
                            <h6 className='font-bold text-white text-[18px] leading-6'>{strings.footer.header1} </h6>
                            <a href="mailto:geral@dionamite.pt" className='flex items-center mt-6 font-bold uppercase text-dionamiteOrange text-[12px] leading-4 tracking-[2px]'>
                                <MdOutlineMail className='mr-2 text-base' /> GERAL@DIONAMITE.PT
                            </a>
                            <a href='tel:+351 917 549 262' className='flex items-center mt-4 font-bold uppercase text-dionamiteOrange text-[12px] leading-4 tracking-[2px]'>
                                <MdOutlineMessage className='mr-2 text-base' />  pt +351 917 549 262
                            </a>
                            {/* <a href='tel:+55 (73) 99801-0433' className='flex items-center mt-4 font-bold uppercase transition-opacity text-buttonText opacity-70 hover:opacity-100'>
                    <MdOutlineMessage className='mr-2 text-base' /> br +55 (73) 99801-0433
                </a> */}
                        </div>
                        <div>
                            <h6 className='font-bold text-white sm:mt-0 text-[18px] leading-6 '>{strings.footer.header2}</h6>
                            <div className='flex gap-8 mt-[18px] opacity-70'>
                                <a href='https://www.instagram.com/dionamite.academy' target="_blank" className=''>
                                    <img src='/Instagram.png' className='size-8' />
                                </a>
                                <a href='https://www.linkedin.com/company/dionamite/' target="_blank" className=''>
                                    <img src='/Linkedin.png' className='size-8' />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='border-t border-[#000] border-opacity-20 md:w-[570px] mx-auto mt-[46px]'>
                        <div className='flex flex-col items-center justify-center gap-2 mt-6 mb-[73px] sm:flex-row'>
                            <p className='font-medium text-center text-[14px] leading-6 text-primary-hover'>
                                {`Dionamite © 2024 - ${strings.footer.rights}`}
                            </p>
                            <Link to={"/privacy-policy"} className='font-semibold underline text-center text-[14px] leading-6 text-dionamiteOrange underline-offset-[3px]'>
                                {strings.footer.privacyPolicy}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
