import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { AuthContext } from "../../context/auth.context";
import { LanguageContext } from "../../context/language.context";
import { v4 as uuidv4 } from "uuid";
import { SlSpeedometer } from "react-icons/sl";
import { GrTask } from "react-icons/gr";
import { FaHeart, FaRegHourglassHalf, FaVolumeHigh } from "react-icons/fa6";
import { FaBookOpen, FaUser, FaStar } from 'react-icons/fa';
import Loading from "../../components/Loading/Loading";
import toast from 'react-hot-toast';

// Load your Stripe publishable key
const stripePromise = loadStripe('pk_live_51OMSxBEMfCrRFy2WL1NyIRVd3YrbqU6XaEEpD7v4HVR6mb0RedO0hMrJVY8XGLRRrTjiggMlTV4VsMF2L8rbojaV00LrPzVYd2');

const CheckoutPage = () => {
    const { slug } = useParams();
    const { user } = useContext(AuthContext);
    const [course, setCourse] = useState('');
    const [amount, setAmount] = useState('');
    const [finalPrice, setFinalPrice] = useState('')
    const [clientSecret, setClientSecret] = useState('')
    const [coupon, setCoupon] = useState("");
    const [validCoupon, setValidCoupon] = useState(null);
    const [couponApplied, setCouponApplied] = useState(false);
    const { strings, countryCode } = useContext(LanguageContext);
    const [refreshKey, setRefreshKey] = useState(Date.now());
    const [currency, setCurrency] = useState(
        countryCode === "BR" ? "brl" : "eur"
    );
    const [hideCheckout, setHideCheckout] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    const handleRefresh = () => {
        setClientSecret("");
        setRefreshKey(Date.now());
    };

    // Function to check if course is included in specific coupon
    const isCourseApplicable = (couponCourses) => {
        // Assuming couponCourses is an array of ObjectIds or course identifiers
        return couponCourses.some(courseData => courseData._id.toString() === course._id.toString()); // Convert to string for comparison
    };

    const handleCoupon = async () => {
        try {
            const res = await axios.get(`https://dionamiteacademyapi.com:41278/coupon/getCoupon/${coupon}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
            });
            const couponData = res.data;

            if (couponData) {
                // Check expiration and other conditions
                const now = new Date(); // Get current date
                const couponExpiration = new Date(couponData.expiration); // Convert expiration string to date object (assuming format)
                const userId = user?._id; // Assuming `user` is defined and contains the current user's data

                // Check if the coupon is expired or invalid
                if (couponExpiration > now && (couponData.percentage != null || couponData.price != null)) {
                    // Check if the coupon is one-time use and if the user has already used it
                    if (couponData.usageLimit === 'one-time' && couponData.usedBy.includes(userId)) {
                        setValidCoupon(false);
                        toast.error("Você já usou este cupão"); // User already used this coupon
                        return;
                    }

                    // Check coupon type and course applicability (existing logic)
                    if (couponData.availability === 'general' || (couponData.availability === 'specific' && isCourseApplicable(couponData.courses))) {
                        setValidCoupon(true);

                        if (couponData.type === 'percentage') {
                            const percentage = couponData.percentage / 100;
                            const updatedAmount = course.price * (1 - percentage);
                            setAmount(updatedAmount.toFixed(2));
                        } else if (couponData.type === 'price') {
                            setAmount(couponData.price.toFixed(2)); // Ensure price is formatted
                        }

                        handleRefresh();
                        setCouponApplied(true);
                        toast.success('Cupão aplicado com sucesso')
                    } else {
                        setValidCoupon(false);
                        toast.error("Cupão não aplicável a este curso"); // Course not applicable
                    }
                } else {
                    setValidCoupon(false);
                    toast.error("Cupão inválido ou expirado"); // Invalid or expired coupon
                }
            } else {
                setValidCoupon(false);
                toast.error("Cupão inválido ou expirado"); // No coupon data received (potentially expired)
            }
        } catch (error) {
            console.error("Error fetching coupon details:", error);
            toast.error("Erro ao buscar detalhes do cupão");
        }
    };

    const checkPaymentIntentStatus = async (paymentIntentId) => {
        try {
            const response = await axios.patch(`https://dionamiteacademyapi.com:41278/stripe/verify-and-purchase`, {
                paymentIntentId,
                userId: user?._id,
            }, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }
            });

            if (response.data.success === true) {
                setHideCheckout(true);
                /*  navigate(`/course/${courseId}`); */
                toast.success('Curso comprado com successo!');
            }
        } catch (error) {
            console.error('Error checking payment intent status:', error);
            toast.error(error.response.data.message);
        }
    };

    useEffect(() => {
        const fetchCourseAndCreateIntent = async () => {
            try {
                // Fetch course details
                const courseResponse = await axios.get(`https://dionamiteacademyapi.com:41278/course/getCourseBySlug/${slug}`);
                setCourse(courseResponse.data);

                // Check if the course has already been purchased by the user
                const hasPurchased = courseResponse.data.purchased?.some(purchase => purchase.userId === user?._id);

                if (hasPurchased) {
                    navigate(`/course/${slug}`)
                    return
                }

                let price = courseResponse.data.price

                if (amount) {
                    price = amount
                }

                setFinalPrice(price)

                if (courseResponse.data && courseResponse.data.price) {
                    const idempotencyKey = uuidv4();
                    const paymentIntentData = {
                        amount: price,
                        courseId: courseResponse?.data?._id,
                        userId: user?._id,
                        idempotencyKey,
                    };

                    if (coupon) {
                        paymentIntentData.coupon = coupon;
                    }

                    const paymentIntentResponse = await axios.post(`https://dionamiteacademyapi.com:41278/stripe/create-payment-intent`, paymentIntentData, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                        }
                    });
                    setClientSecret(paymentIntentResponse.data.clientSecret);
                }
            } catch (error) {
                console.error('Error setting up payment:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchCourseAndCreateIntent();

        // Check payment intent status if paymentIntent is in URL
        const queryParams = new URLSearchParams(location.search);
        const paymentIntent = queryParams.get('payment_intent');
        if (paymentIntent) {
            checkPaymentIntentStatus(paymentIntent);
        }
    }, [slug, user?._id, amount, currency, location.search]);

    const appearance = {
        theme: "night",
        labels: "floating",
        variables: {
            colorPrimary: "#6753B4",
            colorBackground: "#27272a",
            colorDanger: "#df1b41",
            fontFamily: "Inter, Ideal Sans, system-ui, sans-serif",
            borderRadius: "4px",
        },
    };

    const audioOptions = {
        portuguese: 'Português',
        english: 'Inglês'
    };

    const typeOptions = {
        online: 'Online',
        accompanied: 'Acompanhado'
    };

    const difficultyOptions = {
        beginner: 'Fácil',
        intermediate: 'Médio',
        advanced: 'Difícil',
        'ultra-advanced': 'Ultra-Avançado'
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            {course && clientSecret ? (
                <div className="mb-20 lg:py-8 containerNavbar">

                    <div className='flex flex-col sm:flex-row gap-x-10'>
                        <div className="w-full lg:w-3/4 sm:w-2/3">
                            <h1 className="mb-6 text-2xl font-bold text-white sm:text-4xl">Checkout</h1>
                            {!hideCheckout ? <>
                                <Elements key={refreshKey} stripe={stripePromise} options={{ clientSecret, appearance }}>
                                    <CheckoutForm courseId={course?._id} amount={finalPrice} currency={currency} userId={user._id} />
                                </Elements>
                                <div className="flex max-w-md mx-auto my-10 space-x-4">
                                    <input
                                        type="text"
                                        value={coupon}
                                        onChange={(e) => setCoupon(e.target.value)}
                                        placeholder={strings.purchase.couponPlaceholder}
                                        className="w-full px-4 h-auto text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit"
                                        disabled={couponApplied}
                                        maxLength={30}
                                    />
                                    <button
                                        onClick={coupon ? handleCoupon : null}
                                        disabled={couponApplied}
                                        className="sm:flex-shrink-0 hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]"
                                    >
                                        {strings.purchase.applyCoupon}
                                    </button>
                                </div>
                            </> : <div className='flex justify-center mt-10'>
                                <button
                                    onClick={() => navigate(`/course/${slug}`)}
                                    className="hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]"
                                >
                                    Ir para curso
                                </button>
                            </div>}
                        </div>
                        <div className="px-4 mt-5 space-y-4 text-white sm:w-1/3 lg:w-1/4">
                            <h2 className="text-xl font-bold">{course.title}</h2>
                            <img src={course.image} alt={course.title} className='pb-3' />
                            <div className='py-4 space-y-4 text-sm text-white border-gray-200 border-y'>
                                <div className='flex items-start mb-2'>
                                    <FaRegHourglassHalf className='flex-shrink-0 mr-2 mt-[3px] text-dionamiteOrange' />
                                    <div className='flex flex-col space-y-[2px]'>
                                        <b>{strings.courses.course.duration}:</b>
                                        <span>{course?.duration} {strings.courses.course.hours}</span>
                                    </div>
                                </div>
                                <div className='flex items-start mb-2'>
                                    <FaBookOpen className='flex-shrink-0 mr-2 mt-[3px] text-green-500' />
                                    <div className='flex flex-col space-y-[2px]'>
                                        <b>{strings.courses.course.type}:</b>
                                        <span>{typeOptions[course?.type]}</span>
                                    </div>
                                </div>
                                <div className='flex items-start mb-2'>
                                    <FaVolumeHigh className='flex-shrink-0 mr-2 mt-[3px] text-blue-500' />
                                    <div className='flex flex-col space-y-[2px]'>
                                        <b>{strings.courses.course.audio}:</b>
                                        <span>{audioOptions[course?.audio]}</span>
                                    </div>
                                </div>
                                <div className='flex items-start mb-2'>
                                    <SlSpeedometer className='flex-shrink-0 mr-2 mt-[3px] text-red-500' />
                                    <div className='flex flex-col space-y-[2px]'>
                                        <b>Dificuldade:</b>
                                        <span>{difficultyOptions[course?.difficulty]}</span>
                                    </div>
                                </div>
                                <div className='flex items-start mb-2'>
                                    <GrTask className='flex-shrink-0 mr-2 mt-[3px] text-primary' />
                                    <div className='flex flex-col space-y-[2px]'>
                                        <b>{strings.courses.course.requirements}:</b>
                                        <span>{course?.requirements}</span>
                                    </div>
                                </div>
                                <div className='flex items-start mb-2'>
                                    <FaUser className='flex-shrink-0 mr-2 mt-[3px] text-pink-500' />
                                    <div className='flex flex-col space-y-[2px]'>
                                        <b>{strings.courses.course.forWhom}:</b>
                                        <span>{course?.forWhom}</span>
                                    </div>
                                </div>
                                {course?.likedBy?.length > 0 && (
                                    <div className='flex items-start mb-2'>
                                        <FaHeart className='flex-shrink-0 mr-2 mt-[3px] text-red-500' />
                                        <div className='flex flex-col space-y-[2px]'>
                                            <b>{strings.courses.course.likes}:</b>
                                            <span>{course?.likedBy?.length}</span>
                                        </div>
                                    </div>
                                )}
                                {course?.rating > 0 && (
                                    <div className='flex items-start mb-2'>
                                        <FaStar className='flex-shrink-0 mr-2 mt-[3px] text-yellow-500' />
                                        <div className='flex flex-col space-y-[2px]'>
                                            <b>{strings.courses.course.rating}":</b>
                                            <span>{course?.rating}/5</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div >
            ) : (
                <Loading />
            )
            }
        </>
    );
}

export default CheckoutPage;
