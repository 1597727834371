import { createContext, useState, useEffect } from "react";
import stringsGlobal from "../strings.json"
import axios from "axios";

const LanguageContext = createContext();

// CREATE A WRAPPER COMPONENT
function LanguageProviderWrapper(props) {
    const [language, setLanguage] = useState(localStorage.getItem('appLanguage') || "PT");
    const [strings, setStrings] = useState(stringsGlobal["PT"])
/*     const [countryCode, setCountryCode] = useState(localStorage.getItem('appCountryCode') || 'PT'); */
    const countryCodeExpiryHours = 6;

    useEffect(() => {
        localStorage.setItem('appLanguage', language);
        setStrings(stringsGlobal[language]);
    }, [language]);

    // Fetch country code on component mount
/*     useEffect(() => {
        const storedCountryCode = localStorage.getItem('appCountryCode');
        const storedTimestamp = localStorage.getItem('appCountryCodeTimestamp');

        const isExpired = () => {
            const now = new Date().getTime();
            const expiryTime = storedTimestamp ? new Date(parseInt(storedTimestamp)).getTime() + countryCodeExpiryHours * 60 * 60 * 1000 : 0;
            return now > expiryTime;
        };

        if (!storedCountryCode || isExpired()) {
            axios.get('https://ipapi.co/json/')
                .then(response => {
                    const code = response.data.country_code; // use response.data.country_code in production
                    const now = new Date().getTime();
                    setCountryCode(code);
                    localStorage.setItem('appCountryCode', code);
                    localStorage.setItem('appCountryCodeTimestamp', now.toString());
                })
                .catch(error => console.error("Error fetching location:", error));
        } else {
            setCountryCode(storedCountryCode);
        }
    }, []); */

    return (
        <LanguageContext.Provider value={{ strings, language, setLanguage/* , countryCode, setCountryCode */ }}>
            {props.children}
        </LanguageContext.Provider>
    );
}

export { LanguageContext, LanguageProviderWrapper };