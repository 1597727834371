import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import authService from "../../services/auth.service";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from 'axios';
import { AuthContext } from "../../context/auth.context";
import { useGoogleLogin } from '@react-oauth/google';
import { LanguageContext } from '../../context/language.context';

function SignupPage() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const { storeToken, authenticateUser } = useContext(AuthContext);
  const { strings } = useContext(LanguageContext);

  const navigate = useNavigate();

  const handleFirstName = (e) => setFirstName(e.target.value);
  const handleLastName = (e) => setLastName(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);
  const handleRepeatPassword = (e) => setRepeatPassword(e.target.value);

  const isPasswordValid = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);

    return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber
    );
  };

  const handleSignupSubmit = (e) => {
    e.preventDefault();

    // Check if the email and password are provided
    if (!email || !password || !firstName || !lastName) {
      setErrorMessage("Por favor, forneça um primeiro nome, apelido, email e palavra-passe.");
      return;
    }

    // Check if the email is valid
    if (!email.includes("@")) {
      setErrorMessage("Por favor, insira um email valido");
      return;
    }

    // Check if the password is valid using the isPasswordValid function
    if (!isPasswordValid(password)) {
      setErrorMessage("A sua palavra-passe deve ter pelo menos 8 caracteres e conter pelo menos 1 letra maiúscula, 1 letra minúscula e 1 número.");
      return;
    }

    // Check if the password and repeatPassword match
    if (password !== repeatPassword) {
      setErrorMessage("As palavras-passes não coincidem");
      return;
    }

    // Create an object representing the request body
    const requestBody = { email, password, firstName, lastName };

    // Send a request to the server using a service
    authService
      .signup(requestBody)
      .then((response) => {
        // If the POST request is successful redirect to the login page
        /* navigate("/login"); */
        navigate("/verify", { state: { email } });
      })
      .catch((error) => {
        // If the request resolves with an error, set the error message in the state
        const errorDescription = error.response.data.message;
        setErrorMessage(errorDescription);
      });
  };

  //Create an account with google
  const signInGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const res = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        });

        const requestBody = { email: res.data.email, googleCredential: res.data.sub, firstName: res.data.given_name, lastName: res.data.family_name, profilePicture: res.data.picture };

        // Send a request to the server using a service
        authService
          .googleAuth(requestBody)
          .then((response) => {
            storeToken(response.data.accessToken);
            authenticateUser();
            // If the POST request is successful, redirect to the main page
            navigate("/");
          })
          .catch((error) => {
            console.log("ERR", error)
            // If the request resolves with an error, set the error message in the state
            const errorDescription = error.response.data.message;
            setErrorMessage(errorDescription);
          });
      } catch (error) {
        console.log(error);
      }
    }
  });

  return (
    <div className="items-center justify-start mb-20 containerNavbar">
      <div className="flex flex-col justify-center p-6 lg:p-8 bg-[#FFFFFF1A] lg:w-1/2 rounded-[16px] lg:mt-[5%] border border-[#A892FF33] max-w-[500px] lg:max-w-none w-full">
        <h2 className="text-center text-white text-[20px] sm:text-[24px] leading-7 font-bold mb-7">Começa a aprender agora</h2>
        <div className="relative flex flex-row">
          <button onClick={() => signInGoogle()} className="flex items-center justify-center w-full p-2 text-[12px] sm:text-sm font-bold text-primary bg-white rounded pl-9">
            <img src="/google.png" alt="Google Logo" className="absolute w-4 sm:w-5 left-3" />
            {strings.signup.signUpGoogle}
          </button>
        </div>

        <div className="flex items-center my-3">
          <div className="h-[1px] w-1/2 bg-dionamiteOrange mt-1" /> <span className="p-4 text-sm font-bold text-dionamiteOrange">ou</span> <div className="h-[1px] w-1/2 bg-dionamiteOrange mt-1" />
        </div>

        <form onSubmit={handleSignupSubmit} className="flex flex-col gap-4">
          <div className="flex flex-row gap-4">
            <input className="w-1/2 px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit" type="text" name="firstName" placeholder="Primeiro Nome" value={firstName} onChange={handleFirstName} />
            <input className="w-1/2 px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit" type="text" name="lastName" placeholder="Último Nome" value={lastName} onChange={handleLastName} />
          </div>
          <input className="w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit" type="email" name="email" placeholder="Email" value={email} onChange={handleEmail} />
          <div className="relative flex">
            <input
              className="w-full px-4 h-12 pr-10 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit"
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Palavra-passe"
              value={password}
              onChange={handlePassword}
            />
            <button
              className="absolute right-0 h-12 px-3 py-3 transform -translate-y-1/2 top-1/2"
              type="button"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash className="text-secondary" /> : <FaEye className="text-secondary" />}
            </button>
          </div>
          <div className="relative flex">
            <input
              className="w-full px-4 h-12 pr-10 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit"
              type={showRepeatPassword ? "text" : "password"}
              name="repeatPassword"
              placeholder="Repetir palavra-passe"
              value={repeatPassword}
              onChange={handleRepeatPassword}
            />
            <button
              className="absolute right-0 h-12 px-3 py-3 transform -translate-y-1/2 top-1/2"
              type="button"
              onClick={() => setShowRepeatPassword(!showRepeatPassword)}
            >
              {showRepeatPassword ? <FaEyeSlash className="text-secondary" /> : <FaEye className="text-secondary" />}
            </button>
          </div>
          {errorMessage && <p className="text-sm text-red-500">{errorMessage}</p>}
          <button className="hover:bg-primary-hover transition duration-500 ease-in-out rounded bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]" type="submit">Registar</button>
        </form>
        <div className="flex items-center justify-center mt-6 text-secondary">
          <p className="pr-1 sm:pr-2 text-[12px] font-light sm:text-sm text-nowrap">Já tem uma conta?</p>
          <Link className="font-bold text-[12px] transition-all sm:text-sm hover:underline text-nowrap" to={"/login"}> Iniciar Sessão</Link>
        </div>
        <p className="mt-4 text-xs font-light text-dionamiteOrange">
          Ao inscrever-se, concorda com os <span className="link">Termos de Serviço e a Política de Privacidade</span>, e consente em receber comunicações de marketing da Dionamite Academy no endereço de email fornecido. Pode desativar estes e-mails a qualquer momento.
        </p>
      </div>
    </div>

  );
}

export default SignupPage;
